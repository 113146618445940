import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
//import PropTypes from "prop-types"

import styles from "./promoBanner.module.scss"

/* Swiper import */
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

function PromoBanner() {

  const data = useStaticQuery(graphql`
    {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              stripbar
            }
          }
        }
      }
    }
  `)

  const stripbar = data.allWordpressAcfOptions.edges[0].node.options.stripbar

  // debug
  // console.log(JSON.stringify(data, null, 4))
  // console.log(data)
  // console.log(stripbar)

  return (
    <>
      <div className={styles.container}>
        {Array.from(Array(15), (e, i) => {
          return <div key={i} className={styles.scrolling} aria-hidden="true">{stripbar}</div>
        })}
      </div>
    </>
  )
}
export default PromoBanner
