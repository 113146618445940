import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./footer.module.scss"

function Footer() {

  const data = useStaticQuery(graphql`
    {
      allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "footer"}}) {
        group(field: id) {
          nodes {
            count
            items {
              title
              url
              order
              wordpress_children {
                title
                order
                url
                wordpress_parent
              }
            }
          }
          totalCount
        }
      }
    }
  `)

  const MenuItems = data.allWordpressWpApiMenusMenusItems.group[0].nodes[0].items
  // debug
  // console.log(MenuItems)

  // function parseHtml(htmlToParse) {
  //   const parser = new DOMParser();
  //   var parsed = parser.parseFromString(`<!doctype html><body>${htmlToParse}`, 'text/html').body.textContent;

  //   return parsed;
  // }
  function replaceCMSLink(linkToReplace) {
    var replaced = linkToReplace.replace('https://cmsjiwchoi.chphsalvo.com', '');

    return replaced;
  }

  return (
    <>
      <div id={styles.footer}>
        <div className={'flex'}>

          {MenuItems?.map((item, i) => (
            <div className={'col col-x2 col-s-x1 ta-c'} key={i}>
              <ul className={styles.menu}>
                <h4 className={styles.title} dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                {item.wordpress_children.map((itemChildren, i) => {
                  return (
                    <li key={i}><Link to={replaceCMSLink(itemChildren.url)}><div dangerouslySetInnerHTML={{ __html: itemChildren.title }} /></Link></li>
                  );
                })}
              </ul>
            </div>
          ))}


          {/* <div className={'col col-x2 col-s-x1 ta-c'}>

            <ul className={styles.menu}>
              <h4 className={styles.title}>Ji Won Choi</h4>
              <li><Link to="/about">about</Link></li>
              <li><Link to="/privacy-policy">privacy & cookie policy</Link></li>
            </ul>

          </div>
          <div className={'col col-x2 col-s-x1 ta-c'}>

            <ul className={styles.menu}>
              <h4 className={styles.title}>Customer Care</h4>
              <li><Link to="/contact">contact us</Link></li>
              <li><Link to="/returns-and-refunds-policy/">return & refunds policy</Link></li>
              <li><Link to="/shipping-policy/">shipping policy</Link></li>
            </ul>

          </div> */}

        </div>
      </div>
    </>
  )
}
export default Footer
