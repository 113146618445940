import React, { useState } from "react"

import Cookies from "js-cookie"
import { Link } from "gatsby"
import axios from 'axios';
import useForm from '../../../hooks/useForm';

import styles from "./newsletterPopup.module.scss"

import loading from "../../../images/loading.gif"

function NewsletterPopup(props) {

  switch (Cookies.get('SHOWNLSUB')) {
    case undefined:
      var cookieShowNlSub = true;
      break;

    case 'true':
      var cookieShowNlSub = true;
      break;

    case 'false':
      var cookieShowNlSub = false;
      break;
  }
  // debug
  // console.log(Cookies.get('SHOWNLSUB'), cookieShowNlSub);

  const [show, setShow] = useState(cookieShowNlSub);
  const [disabled, setDisabled] = useState(false);
  const [response, setResponse] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false)

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [policy, setPolicy] = useState(false);

  function closePopup() {
    setShow(false);
    // Set NL Cookie
    Cookies.set("SHOWNLSUB", false, { expires: 1 })
  }

  function subscribeCustomer() {
    // debug
    // console.log('subscribeCustomer');
    setSubscriptionLoading(true);

    let data = JSON.stringify({
      query: `mutation customerCreate($input: CustomerCreateInput!) {
      customerCreate(input: $input) {
        customer {
          id
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }`,
      variables: {
        "input": {
          "email": values.email,
          "password": "hY7sJ@I!s43",
          "firstName": values.firstname,
          "lastName": values.lastname,
          "acceptsMarketing": values.policy ? true : false
        }
      }
    });

    let config = {
      method: 'post',
      url: 'https://jiwonchoi.myshopify.com/api/2021-10/graphql.json',
      headers: {
        'X-Shopify-Storefront-Access-Token': '1606be1610de6661c814f512b4bcdb71',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        // debug
        console.log(response.data);
        console.log(response.data.errors);
        console.log(response.data.data.customerCreate);

        if (response.data.errors) {
          setResponse(response.data.errors[0].message);
        } else if (response.data.data.customerCreate.customerUserErrors.length >= 1) {
          setResponse(response.data.data.customerCreate.customerUserErrors[0].message);
        } else if (response.data.data.customerCreate.customer?.id) {
          setResponse("Thank you! Successfully subscribed");
          Cookies.set("SHOWNLSUB", false, { expires: 365 })
          setDisabled(true);
        } else {
          setResponse("Somethings gone wrong. Please retry leter.")
        }

        setSubscriptionLoading(false);
      });
    // .catch((error) => {
    //   console.log(error);
    //   setResponse(error);
    // });
  }

  //Final submit function
  const formLogin = () => {
    //console.log("Callback function when form is submitted!");
    //console.log("Form Values ", values);
    subscribeCustomer();
  }

  //Custom hook call
  const { handleChange, values, errors, handleSubmit } = useForm(formLogin);


  return (
    <>
      {show && (

        <div className={styles.newsletterPopup}>

          <div className={styles.close} onClick={e => closePopup()}>✕</div>
          <div className={styles.content}>

            <div className={styles.heading}>
              <h4>Subscribe to JWC newsletter</h4>
            </div>

            <form className={"flex"} onSubmit={handleSubmit}>

              <div className={styles.fieldset + " col col-x2"}>
                <input type="text" name="firstname" placeholder="First Name*" onChange={handleChange} />
                {errors.firstname && <span className={styles.error}>{errors.firstname}</span>}
              </div>
              <div className={styles.fieldset + " col col-x2"}>
                <input type="text" name="lastname" placeholder="Last Name*" onChange={handleChange} />
                {errors.lastname && <span className={styles.error}>{errors.lastname}</span>}
              </div>

              <div className={styles.fieldset + " col col-x1"}>
                <input type="email" name="email" placeholder="E-mail*" onChange={handleChange} />
                {errors.email && <span className={styles.error}>{errors.email}</span>}
              </div>

              <div className={styles.fieldset + " " + styles.policy + " col col-x1 flex"} style={{ 'flex-wrap': 'nowrap' }}>
                <input type="checkbox" name="policy" id="policy" onChange={handleChange} />
                <label htmlFor="policy">
                  I agree personal data processed to register for the newsletter as indicated in the <Link to="/privacy-policy">Privacy Policy</Link>
                </label>
              </div>
              {errors.policy && <span className={styles.error}>{errors.policy}</span>}

              <div className={'col-x1 ta-c'}>
                {response && <span className={styles.response}>{response}</span>}
                {errors.general && <span className={styles.error}>{errors.general}</span>}
              </div>

              {subscriptionLoading ? (
                <div className={"btn-primary " + styles.loading}><img src={loading} /></div>
              ) : (
                <input className={"btn-primary " + (disabled ? styles.disabled : "")} type="submit" value="subscribe" />
              )}

            </form>

            {/* <div onClick={() => subscribeCustomer()}>Newsletter Popup</div> */}

          </div>
        </div>
      )}
    </>
  )
}
export default NewsletterPopup
