import React from "react"
//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import Cookies from "js-cookie"

import styles from "./headerNewsletter.module.scss"

import IconNewsletter from "../../images/icon-newsletter.png"

function HeaderNewsletter() {

  function showNewsletterPopup() {
    Cookies.set("SHOWNLSUB", true, { expires: 365 });
    window.scrollTo(0, 0);
    window.location.reload();
  }

  return (
    <>
      <div className={'btn-primary' + ' ' + styles.cta} onClick={e => showNewsletterPopup()}>
        <img className={styles.iconNewsletter} src={IconNewsletter} />
        <a>Newsletter</a>
      </div>
    </>
  )
}
export default HeaderNewsletter
