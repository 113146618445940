import React from "react"
//import { Link } from "gatsby"
import PropTypes from "prop-types"

import styles from "./header.module.scss"

function Header({ children }) {
  return (
    <>
      <div className={styles.header}>{children}</div>
    </>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Header
