import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
//import PropTypes from "prop-types"

import styles from "./menu.module.scss"

function Menu() {
  const [open, setOpen] = useState(false)
  const [hovered, setHovered] = useState(false)
  const toggleHover = () => setHovered(!hovered)
  const data = useStaticQuery(graphql`
    {
      allWordpressWpCollection {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        id={styles.header_menuMobile}
        className={open ? styles.open : styles.closed}
        onClick={() => open ? setOpen(false) : setOpen(true)}
      >
        <a className={'btn btn--hamburgher ' + styles.btnHamburgher}>
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <nav className={
        open ? styles.open : styles.closed
      }>
        <ul>
          <li><Link to="/">home</Link></li>
          <li><Link to="/shop">shop</Link></li>
          <li
            className={hovered ? 'active ' + styles.submenu : '' + styles.submenu}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}>
            <Link to="#">collections</Link>
            <ul>
              {data.allWordpressWpCollection.edges.map(({ node }) => (
                <li><Link to={"/collections/" + node.slug}>{node.title}</Link></li>
              ))}
            </ul>
          </li>
          <li><Link to="/about">about</Link></li>
          <li><Link to="/vision">vision</Link></li>
          <li><Link to="/news">news</Link></li>
          <li><Link to="/contact">contact</Link></li>
        </ul>
      </nav>
    </>
  )
}
export default Menu
