/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// import HeaderLogo from "./headerLogo/headerLogo"
import Header from "./header/header"
import HeaderCart from "./headerCart/headerCart"
import HeaderNewsletter from "./headerNewsletter/headerNewsletter"
import CookieConsent from "react-cookie-consent"
import PromoBanner from "./promoBanner/promoBanner"
import NewsletterPopup from "./newsletter/newsletterPopup/newsletterPopup"
import Menu from "./menu/menu"
import Footer from "./footer/footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="cetynvpca5m49mug59vv2z9s1mbv3b" />
      </Helmet>

      <PromoBanner />
      <Menu />
      {/* <HeaderLogo /> */}
      <Header>
        <HeaderNewsletter />
        <HeaderCart to={'/shop/cart'} />
      </Header>
      <main>
        {children}
        <Footer />
      </main>

      <NewsletterPopup />

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieConsent"
        style={{
          background: "rgba(245, 245, 245, 0.9)",
          color: "black",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          width: "auto",
          //boxShadow: 'rgba(0,0,0,0.3) 10px 10px 10px'
        }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={60}
        //enableDeclineButton
        declineButtonText="Decline"
        buttonStyle={{
          background: "black",
          padding: "8px 16px",
          color: "white",
          fontSize: "10px",
          fontWeight: "800",
          letterSpacing: "0.5px",
          textTransform: "uppercase",
        }}
        declineButtonStyle={{ background: 'transparent', color: 'red' }}
      >
        <span style={{ fontSize: "12px" }}>
          This website uses cookies to enhance the user experience. Browsing this site you accept our <Link to="/privacy-policy" style={{ 'textDecoration': 'underline' }}>Privacy Policy</Link>.
          </span>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
